import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
    DocumentListResponse,
    DocumentInfoResponse,
    GetDocumentInfoPayloadType,
    DocumentResponse,
    GetDocumentListPayloadType,
    DeleteDocumentPayloadType,
    DeleteDocumentResponse,
    DocumentListItemType,
} from '../utils/types';

export const userDocumentsStartState = {
    loadingList: undefined,
    listError: undefined,
    loadingDocument: {},
    deletingDocument: {},
    loadingInfo: {},
    list: [],
    info: {},
    document: {},
    error: {},
};

export interface UserState {
    user?: any;
    userDocuments: {
        loadingList: any;
        listError: any;
        loadingInfo: {
            [key: string]: string | boolean;
        };
        loadingDocument: {
            [key: string]: any;
        };
        deletingDocument: {
            [key: string]: any;
        };
        list: DocumentListItemType[];
        document: { [key: string]: any };
        info: {
            [key: string]: any;
        };
        error: { [key: string]: any };
    };
}

const intialUserState: UserState = {
    user: undefined,
    userDocuments: userDocumentsStartState,
};

export const userSlice = createSlice({
    name: 'user',
    initialState: intialUserState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        logOutUser: state => {
            state.user = undefined;
            state.userDocuments = userDocumentsStartState;
        },
        getDocumentList: (state, action: PayloadAction<GetDocumentListPayloadType>) => {
            state.userDocuments.loadingList = true;
        },
        getDocumentListSuccess: (state, action: PayloadAction<any>) => {
            state.userDocuments.list = action.payload;
            state.userDocuments.loadingList = 'DONE';
        },
        getDocumentListError: (state, action: PayloadAction<DocumentListResponse>) => {
            state.userDocuments.listError = action.payload;
            state.userDocuments.loadingList = 'Error';
        },
        getDocumentInfo: (state, action: PayloadAction<GetDocumentInfoPayloadType>) => {
            state.userDocuments.loadingInfo[action.payload.documentId] = true;
        },
        getDocumentInfoSuccess: (state, action: PayloadAction<DocumentInfoResponse & { documentId: string }>) => {
            state.userDocuments.info[action.payload.documentId] = action.payload;
            state.userDocuments.loadingInfo[action.payload.documentId] = 'DONE';
        },
        getDocumentInfoError: (state, action: PayloadAction<{ message: any; documentId: string }>) => {
            state.userDocuments.error = action.payload;
            state.userDocuments.loadingInfo[action.payload.documentId] = 'Error';
        },
        getDocumentSuccess: (state, action: PayloadAction<DocumentResponse>) => {
            // @ts-ignore
            state.userDocuments.document[action.payload.documentId] = action.payload.document;
            state.userDocuments.loadingDocument[action.payload.documentId] = 'DONE';
        },
        getDocumentError: (state, action: PayloadAction<DocumentResponse>) => {
            state.userDocuments.error = action.payload;
            state.userDocuments.loadingDocument[action.payload.documentId] = 'Error';
        },
        deleteDocument: (state, action: PayloadAction<DeleteDocumentPayloadType>) => {
            // @ts-ignore
            state.userDocuments.deletingDocument[action.payload.documentId] = true;
        },
        deleteDocumentSuccess: (state, action: PayloadAction<DeleteDocumentResponse>) => {
            state.userDocuments.info[action.payload] = undefined; //TODO this might cause error
            state.userDocuments.document[action.payload] = undefined; //TODO this might cause error
            state.userDocuments.deletingDocument[action.payload] = 'DONE';
            state.userDocuments.list = state.userDocuments.list.filter(document => {
                return document.itemId !== action.payload;
            });
        },
        deleteDocumentError: (state, action: PayloadAction<{ message: any; documentId: string }>) => {
            // state.userDocuments.error = action.payload;
            // state.userDocuments.loadingInfo[action.payload.documentId] = 'Error';
        },
    },
});

export const {
    setUser,
    logOutUser,
    getDocumentList,
    getDocumentListSuccess,
    getDocumentListError,
    getDocumentInfo,
    getDocumentInfoSuccess,
    getDocumentInfoError,
    getDocumentSuccess,
    getDocumentError,
    deleteDocument,
    deleteDocumentSuccess,
    deleteDocumentError,
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;
export const selectUserDocumentAll = (state: RootState) => state.user.userDocuments;
export const selectUserDocument = (state: RootState, documentId: string) => {
    return {
        info: state.user.userDocuments.info[documentId],
        error: state.user.userDocuments.error[documentId],
        loadingInfo: state.user.userDocuments.loadingInfo[documentId],
    };
};
// @ts-ignore
export const selectDocument = (state: RootState, documentId: string) => state.user.userDocuments.document[documentId];

export const userReducer = userSlice.reducer;

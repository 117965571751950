import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDocumentInfo, selectUser, selectUserDocument } from '../../app/userSlice';
import ImageDocument from './ImageDocument';
import { CircularProgress } from '@mui/material';

const backButtonStyle = { fontSize: '3rem', marginTop: '5rem', cursor: 'pointer' };

function DocumentView() {
    const { userid, documentId, documentType } =
        useParams<{ userid: string; documentId: string; documentType: string }>();

    const user = useAppSelector(selectUser);
    // @ts-ignore
    const { info, loadingInfo, error } = useAppSelector(s => selectUserDocument(s, documentId));
    const dispatch = useAppDispatch();
    const jwtToken = user?.getSignInUserSession().idToken.getJwtToken();

    useEffect(() => {
        if (jwtToken && userid && documentId && documentType) {
            dispatch(getDocumentInfo({ userid, jwtToken, documentId, documentType }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentId, documentType, userid]);

    if (!info || loadingInfo !== 'DONE') {
        return <CircularProgress color='inherit' />;
    }

    if (error) {
        return (
            <div>
                <h2>DocumentView</h2>
                <h3>{error}</h3>
                <div onClick={() => window.close()} style={backButtonStyle}>
                    <i className={'icon-left-open'} /> Back
                </div>
            </div>
        );
    }

    return (
        <>
            <div>DocumentView</div>
            <div onClick={() => window.close() /*navigate(-1)*/} style={backButtonStyle}>
                <i className={'icon-left-open'} /> Back
            </div>
            {info && documentId && (
                <div>
                    <ImageDocument documentId={documentId} />
                </div>
            )}
        </>
    );
}

export default DocumentView;

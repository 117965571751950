import React, { useEffect, useState } from 'react';
import { Collapse, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { DocumentListItemType } from '../../utils/types';
import { Downloader } from './Downloader';
import { DocumentListViewer } from './DocumentListViewer';
import classnames from 'classnames';
import styles from './DocumentListItem.module.sass';
import { DocTypeIcon } from './DocTypeIcon';
import { DeleteIcon } from './DeleteIcon';

function DocumentListItem({
    document,
    userid,
    expanded = false,
}: {
    document: DocumentListItemType;
    userid: string;
    expanded: boolean;
}) {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [downLoad, setDownLoad] = useState(0);

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);

    const isPdf = document.filename.split('.').pop() === 'pdf';
    const { documentType } = document;

    return (
        <div>
            <ListItemButton key={document.itemId} sx={{ fontSize: 18 }}>
                {document.documentStatus === 'RECEIVED' ? (
                    <ListItemIcon>
                        <Tooltip title={'Successfully uploaded'}>
                            <i className={classnames('icon-check', styles.greenCheckmark)} />
                        </Tooltip>
                    </ListItemIcon>
                ) : (
                    <ListItemIcon>
                        <Tooltip title={'Upload started, but not completed'}>
                            <i className={classnames('icon-check', styles.orangeCheckmark)} />
                        </Tooltip>
                    </ListItemIcon>
                )}
                <Tooltip title={documentType}>
                    <ListItemIcon>
                        <DocTypeIcon documentType={documentType} />
                    </ListItemIcon>
                </Tooltip>
                <ListItemText
                    primaryTypographyProps={{
                        fontSize: 20,
                        fontWeight: 'medium',
                        letterSpacing: 0,
                    }}
                    primary={document.filename}
                    secondary={document.documentType + ' ' + document.createdAt.split('T').join(' ').split('.')[0]}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                />
                <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
                    <ListItemIcon
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    >
                        <i className={classnames(styles.iconSize, isExpanded ? 'icon-angle-up' : 'icon-angle-down')} />
                    </ListItemIcon>
                </Tooltip>

                <DeleteIcon document={document} />

                {false && (
                    <Tooltip title='Download'>
                        <ListItemIcon>
                            <i
                                className={classnames(styles.iconSize, 'icon-down-circled')}
                                onClick={() => setDownLoad(downLoad + 1)}
                            />
                        </ListItemIcon>
                    </Tooltip>
                )}
                {!isPdf && (
                    <Tooltip title='open in new window'>
                        <ListItemIcon>
                            <i
                                className={classnames(styles.iconSize, 'icon-menu-objektupload')}
                                onClick={() =>
                                    window.open('/' + userid + '/' + document.documentType + '/' + document.itemId)
                                }
                            />
                        </ListItemIcon>
                    </Tooltip>
                )}
            </ListItemButton>
            <Collapse mountOnEnter in={isExpanded}>
                <DocumentListViewer document={document} userid={userid} filename={document.filename} />
            </Collapse>
            {downLoad > 0 && <Downloader key={downLoad} document={document} userid={userid} />}
        </div>
    );
}

export default DocumentListItem;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { selectUser, getDocumentList, selectUserDocumentAll } from '../../app/userSlice';
import styles from './DocumentOverviewPage.module.sass';
import { CircularProgress, List, ListSubheader } from '@mui/material';
import DocumentListItem from './DocumentListItem';
import { DocumentListItemType } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import { stage } from '../../utils/constants';
import '../../icons/css/fontello.css';

function DocumentOverviewPage() {
    const [expandAll, setExpandAll] = useState<string>(localStorage.getItem('expandAll') === 'y' ? 'y' : 'n');
    const [alwaysExpandAll, setAlwaysExpandAll] = useState<string>(
        localStorage.getItem('expandAll') === 'y' ? 'y' : 'n'
    );

    const { userid } = useParams<{ userid: string }>();
    const user = useAppSelector(selectUser);
    const { list, loadingList, listError } = useAppSelector(selectUserDocumentAll);
    const dispatch = useAppDispatch();
    const jwtToken = user?.getSignInUserSession().idToken.getJwtToken();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (jwtToken && userid) {
            dispatch(getDocumentList({ userid, jwtToken }));
        }
    }, [jwtToken, dispatch, userid]);

    if (listError) {
        return (
            <div>
                <h2>DocumentOverview</h2>
                <h3>{listError}</h3>
                <div onClick={() => navigate('/')} className={styles.errorBack}>
                    <i className={'icon-left-open'} /> Back
                </div>
            </div>
        );
    }

    if (!list || loadingList !== 'DONE') {
        return <CircularProgress color='inherit' />;
    }

    return (
        <div className={styles.documentOverview}>
            <h2>Document Overview</h2>
            {/*<h3>*/}
            {/*    {'user email: '} <b> {userIdentity}</b>*/}
            {/*</h3>*/}
            <h3>{'You are viewing the documents for '}</h3>
            <h3>{'userId: ' + userid}</h3>
            <div>
                <div className={styles.expandControlsContainer}>
                    {userid && (
                        <a href={t(`salesForceLink.${stage}`) + userid}>
                            <button className={'btn-primary'}>Go to salesforce profile</button>
                        </a>
                    )}
                    <button className={'btn-primary'} onClick={() => setExpandAll(expandAll === 'y' ? 'n' : 'y')}>
                        {expandAll === 'y' ? 'Collapse all' : 'Expand all'}
                    </button>

                    <div>
                        Always expand all
                        <input
                            type='checkbox'
                            name='auto login'
                            checked={alwaysExpandAll === 'y'}
                            onChange={() => {
                                setAlwaysExpandAll(alwaysExpandAll === 'y' ? 'n' : 'y');
                                setExpandAll(alwaysExpandAll === 'y' ? 'n' : 'y');
                                localStorage.setItem('expandAll', alwaysExpandAll === 'y' ? 'n' : 'y');
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.listWrapper}>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component='nav'
                    aria-labelledby='nested-list-subheader'
                    subheader={
                        <ListSubheader sx={{ fontSize: 20 }} component='div' id='nested-list-subheader'>
                            Documents
                        </ListSubheader>
                    }
                >
                    {list &&
                        list.map((document: DocumentListItemType) => {
                            return (
                                <DocumentListItem
                                    key={document.itemId}
                                    document={document}
                                    userid={userid || ''}
                                    expanded={expandAll === 'y'}
                                />
                            );
                        })}
                </List>
            </div>
        </div>
    );
}

export default DocumentOverviewPage;

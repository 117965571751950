import { DocumentListItemType } from '../../utils/types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDocumentInfo, selectDocument, selectUser, selectUserDocument } from '../../app/userSlice';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import ImageDocument from '../DocumentView/ImageDocument';
import pdfSvg from './pdf-svg.svg';

export const DocumentListViewer = ({
    document: { itemId, documentType },
    userid,
    filename,
}: {
    document: DocumentListItemType;
    userid: string;
    filename?: string;
}) => {
    const user = useAppSelector(selectUser);
    const jwtToken = user?.getSignInUserSession().idToken.getJwtToken();
    const { info, loadingInfo, error } = useAppSelector(s => selectUserDocument(s, itemId));
    const dispatch = useAppDispatch();
    const isPdf = filename?.split('.').pop() === 'pdf';
    const document = useAppSelector(state => selectDocument(state, itemId));
    // const [pdf, setPdf] = useState(undefined);

    useEffect(() => {
        if (jwtToken && userid && itemId && documentType && loadingInfo !== 'DONE') {
            dispatch(getDocumentInfo({ userid, jwtToken, documentId: itemId, documentType }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId, documentType, userid, jwtToken]);

    // useEffect(() => {
    //     const tt = async () => {
    //         // @ts-ignore
    //         if (isPdf && document && loadingInfo === 'DONE') {
    //             let blob = await fetch(document).then(r => r.blob());
    //             const pdf = await blobToBase64(blob);
    //             // @ts-ignore
    //             setPdf(pdf);
    //         }
    //     };
    //
    //     tt();
    // }, [document]);

    if (!info || loadingInfo !== 'DONE') {
        return <CircularProgress color='inherit' />;
    }

    if (error) {
        return (
            <div>
                <h3>{error}</h3>
            </div>
        );
    }

    return (
        <>
            {info && itemId && (
                <div>
                    {isPdf && document ? (
                        <div
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'start',
                                flexDirection: 'column',
                            }}
                            onClick={() => {
                                window.open(document);
                            }}
                        >
                            <img style={{ maxHeight: '100px' }} src={pdfSvg} alt={''} />
                            Click here to view pdf
                        </div>
                    ) : (
                        <ImageDocument documentId={itemId} />
                    )}
                </div>
            )}
        </>
    );
};

// function blobToBase64(blob: any) {
//     return new Promise((resolve, _) => {
//         const reader = new FileReader();
//         reader.onloadend = () => resolve(reader.result);
//         reader.readAsDataURL(blob);
//     });
// }

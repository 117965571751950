import React, { SyntheticEvent, useState } from 'react';
// import { useAppSelector } from '../app/hooks';
// import { selectUser } from '../app/userSlice';
import MenuItem from './MenuItem';
import { useTranslation } from 'react-i18next';
import { stage } from '../utils/constants';
import { NameType, PageHeaderContentType } from '../App';

type PageHeaderType = PageHeaderContentType & {
    active?: string;
    imageUrl?: (s: string) => string;
};

function PageHeader({ imageUrl = url => `https://static.ownr.eu/img${url}`, menu, active }: PageHeaderType) {
    // const user = useAppSelector(selectUser);
    const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);
    const [navbarIsOpen, setNavbarIsOpen] = useState(false);
    // const [loading, setLoading] = useState(true);

    const handleToggleNavbar = () => {
        setUserMenuIsOpen(false);
        setNavbarIsOpen(!navbarIsOpen);
    };

    // const handleToggleProfileNav = () => {
    //     setUserMenuIsOpen(!userMenuIsOpen);
    //     setNavbarIsOpen(false);
    // };
    const handleCloseAllNavs = () => {
        setUserMenuIsOpen(false);
        setNavbarIsOpen(false);
    };

    // const handleMenuChanged = menuItem => {
    //     setNavbarIsOpen(false);
    //     if (this.props.onMenuChanged) {
    //         this.props.onMenuChanged(menuItem);
    //     }
    // };

    return (
        <header
            className={'navbar navbar-default navbar-has-image navbar-top navbar-sf' + (navbarIsOpen ? ' _isOpen' : '')}
        >
            <div className='container'>
                <div className='navbar-header'>
                    <BurgerButton onClick={handleToggleNavbar} />
                    <LogoWrap imageUrl={imageUrl} />
                    <ul className='nav navbar-nav navbar-main navbar-sf hidden-xs'>
                        <LinkList menu={menu} active={active} />
                    </ul>
                </div>
            </div>
            <div
                className={'__menuBackdrop' + (navbarIsOpen || userMenuIsOpen ? ' _isVisible' : '')}
                onClick={handleCloseAllNavs}
            />
        </header>
    );
}

export default PageHeader;

export const LogoWrap = ({ imageUrl }: { imageUrl: (s: string) => string }) => {
    const { t } = useTranslation();
    return (
        <div className={'logoWrap'}>
            <a className='navbar-brand navbar-brand-image' href={t(`links.typo3.home.${stage}`)} title='My OWNR'>
                <img
                    alt='ownr-logo'
                    className='navbar-brand-logo-normal'
                    src={imageUrl('/logo/ownr-logo-noclaim.svg')}
                />
            </a>
        </div>
    );
};

export const BurgerButton = ({ onClick }: { onClick: (e: SyntheticEvent) => void }) => {
    return (
        <button
            aria-expanded='false'
            className='navbar-toggle collapsed visible-xs'
            data-target='#mainNavbarCollapse'
            data-toggle='collapse'
            onClick={onClick}
        >
            <span className='burgerLine' />
            <span className='burgerLine' />
            <span className='burgerLine' />
            <span className='burgerLine' />
            <span className='burgerLine' />
            <span className='burgerLine' />
        </button>
    );
};

const LinkList = ({ menu, active }: { menu: { name: NameType; label: string; link: string }[]; active?: string }) => {
    // if (!menuItems) {
    //     menuItems = [{ name: 'search' }, { name: 'mapSearch' }];
    //     if (user) {
    //         menuItems = [...menuItems, { name: 'myObjects' }, { name: 'faq' }];
    //     }
    // }

    return menu.length > 0 ? (
        <>
            {menu.map((menuItem: any) => {
                return (
                    <MenuItem
                        active={active === menuItem.name}
                        key={menuItem.name}
                        label={menuItem.label}
                        link={menuItem.link}
                        name={menuItem.name}
                        onClick={menuItem.onClick}
                    />
                );
            })}
        </>
    ) : (
        <></>
    );
};

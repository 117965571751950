import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logOutUser, selectUser } from '../../app/userSlice';
import styles from './LoginPage.module.sass';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const [autoLogin, setAutoLogin] = useState<string>(localStorage.getItem('autoLogin') === 'y' ? 'y' : 'n');
    const redir = localStorage.getItem('loginRedirect');

    useEffect(() => {
        if (autoLogin === 'y' && redir !== '/') {
            Auth.federatedSignIn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (user) {
        const redir = localStorage.getItem('loginRedirect');
        if (redir !== '/login') {
            console.log(redir);
            // @ts-ignore
            navigate(redir || '/');
        }
    }

    if (autoLogin !== 'n') {
        return <></>;
    }

    //TODO add localstorage redirect login thing

    return (
        <div className={styles.loginWrapper}>
            {/*<h2>User: {user ? JSON.stringify(user?.username.split('Azure_')[1]) : 'None'}</h2>*/}

            {user ? (
                <>
                    <button
                        className={'btn-primary ' + styles.loginButton}
                        // variant={'contained'}
                        // size={'large'}
                        onClick={() => {
                            Auth.signOut(); //TODO move this to saga?
                            dispatch(logOutUser);
                        }}
                    >
                        Sign Out
                    </button>
                    <AutoLogin autoLogin={autoLogin} setAutoLogin={setAutoLogin} />
                </>
            ) : (
                <>
                    <h3>You need to be logged in to access this page</h3>
                    <h3>Please log in</h3>
                    <button
                        className={'btn-primary  ' + styles.loginButton}
                        // variant={'contained'}
                        onClick={() => Auth.federatedSignIn()}
                        // onClick={() =>
                        //     (window.location.href = `https://cdkmadedomain.auth.eu-central-1.amazoncognito.com/
                        //     login?redirect_uri=http://localhost:3000${from}&response_type=token&
                        //     client_id=6rkdbrpsdp1hos295gf57lp54e&identity_provider=COGNITO&scope=email openid`)
                        // }
                    >
                        Federated Sign In
                    </button>
                    <AutoLogin autoLogin={autoLogin} setAutoLogin={setAutoLogin} />
                </>
            )}
        </div>
    );
}

const AutoLogin = ({ autoLogin, setAutoLogin }: { autoLogin: string; setAutoLogin: (s: string) => void }) => {
    return (
        <>
            <p>Next-time auto-login with the federated sign in? </p>
            <p>
                <input
                    type='checkbox'
                    name='auto login'
                    checked={autoLogin === 'y'}
                    onClick={() => {
                        setAutoLogin(autoLogin === 'y' ? 'n' : 'y');
                        localStorage.setItem('autoLogin', autoLogin === 'y' ? 'n' : 'y');
                    }}
                />
                <label htmlFor='auto login'>Auto login</label>
            </p>
        </>
    );
};

export default LoginPage;

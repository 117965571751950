import { DocumentListItemType } from '../../utils/types';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getDocumentInfo, selectDocument, selectUser, selectUserDocument } from '../../app/userSlice';
import React, { useEffect } from 'react';

function download(url: string, filename: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}

export const Downloader = ({
    document: { itemId, documentType, filename },
    userid,
}: {
    document: DocumentListItemType;
    userid: string;
}) => {
    const user = useAppSelector(selectUser);
    const jwtToken = user?.getSignInUserSession().idToken.getJwtToken();
    const { loadingInfo } = useAppSelector(s => selectUserDocument(s, itemId));
    const document = useAppSelector(state => selectDocument(state, itemId));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (jwtToken && userid && itemId && documentType && loadingInfo !== 'DONE') {
            dispatch(getDocumentInfo({ userid, jwtToken, documentId: itemId, documentType }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId, documentType, userid, jwtToken]);

    useEffect(() => {
        if (loadingInfo === 'DONE' && document) {
            console.log(document);
            download(document, filename);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingInfo, document]);

    return <></>;
};

export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';

export const stage = (process.env.ENVIRONMENT as 'local' | 'development' | 'staging' | 'production') || 'development';
export const poolData = {
    userPoolId: process.env.REACT_APP_AMPLIFY_USERPOOL_ID || 'eu-central-1_IpbOk7CPw',
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USERPOOL_WEBCLIENT_ID || '2q04stqk00t4tblhdmlcfrq7bh',
    domain: process.env.REACT_APP_AMPLIFY_DOMAIN || 'ownr-auth-dev.auth.eu-central-1.amazoncognito.com',
    redirectUri: process.env.REACT_APP_REDIRECT_URI || 'https://localhost:3000/',
};
export const awsconfig = {
    Auth: {
        // REQUIRED - Amazon Cognito Region
        region: 'eu-central-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: poolData.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: poolData.userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,

        oauth: {
            domain: poolData.domain,

            scope: ['email', 'openid'],

            redirectSignIn: poolData.redirectUri,
            redirectSignOut: poolData.redirectUri,
            responseType: 'token',

            // optional, for Cognito hosted ui specified options
            options: {
                // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
                AdvancedSecurityDataCollectionFlag: false,
            },
        },
    },
};

import React, { useState } from 'react';
import { Backdrop, Checkbox, FormControlLabel, FormGroup, ListItemIcon, Tooltip } from '@mui/material';
import classnames from 'classnames';
import styles from './DocumentListItem.module.sass';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DocumentListItemType } from '../../utils/types';
import { deleteDocument, selectUser } from '../../app/userSlice';

type DeleteIconType = {
    document: DocumentListItemType;
};

export const DeleteIcon = ({ document }: DeleteIconType) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const jwtToken = user?.getSignInUserSession().idToken.getJwtToken();

    return (
        <>
            <Tooltip title='Delete'>
                <ListItemIcon>
                    <i className={classnames(styles.iconSize, 'icon-trash')} onClick={() => setShowDeleteModal(true)} />
                </ListItemIcon>
            </Tooltip>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={showDeleteModal}>
                    <div className={styles.modalContainer}>
                        <p>
                            <h3>Are you sure you want to delete this document?</h3>
                        </p>
                        <div className={classnames(styles.rowFlex)}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox onChange={() => setDisableButton(!disableButton)} />}
                                    label={<h4 style={{ margin: '0' }}>{'I am sure'}</h4>}
                                    sx={{ fontSize: '2rem' }}
                                />
                            </FormGroup>
                            <button
                                className={classnames({
                                    'btn-default': !disableButton,
                                    'btn-secondary': disableButton,
                                })}
                                disabled={disableButton}
                                onClick={() => {
                                    setShowDeleteModal(false);
                                    dispatch(deleteDocument({ jwtToken, ...document }));
                                }}
                            >
                                {'Delete document'}
                            </button>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

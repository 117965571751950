import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectDocument } from '../../app/userSlice';

type DocumentType = {
    documentId: string;
};

// function showInOtherTab(blob: Blob) {
//     const url = window.URL.createObjectURL(blob);
//     window.open(url);
// }

function ImageDocument({ documentId }: DocumentType) {
    const document = useAppSelector(state => selectDocument(state, documentId));
    return (
        <div>
            <img alt='' style={imgStyle} src={document} />
        </div>
    );
}

const imgStyle = { maxWidth: '100%' };

export default ImageDocument;

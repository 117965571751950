import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectUser } from '../app/userSlice';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

export function RequireAuth({ children }: { children: JSX.Element }) {
    //TODO add custom useAuth hook let auth = useAuth();
    let location = useLocation();
    const [state, setState] = useState<any>(undefined);
    const user = useAppSelector(selectUser);

    useEffect(() => {
        // @ts-ignore
        if (location?.state?.clearLocalStorage) {
            localStorage.setItem('loginRedirect', '');
        }
        // @ts-ignore
    }, [location?.state?.clearLocalStorage]);

    useEffect(() => {
        const getAuth = async () => {
            try {
                await Auth.currentAuthenticatedUser();
            } catch (error) {
                setState('Not signed in');
            }
        };
        getAuth();
    }, []);

    if (!user && state === 'Not signed in') {
        return <Navigate to='/login' state={{ from: location }} />;
    }

    if (!user) {
        return <CircularProgress color='inherit' />;
    }
    const url = localStorage.getItem('loginRedirect');
    if (user && url !== '') {
        if (typeof url === 'string' && url !== '/' && url !== '') {
            if (url !== location.pathname) {
                return <Navigate to={url} state={{ clearLocalStorage: true }} />;
            } else if (url !== '') {
                localStorage.setItem('loginRedirect', '');
            }
        }
    }
    return children;
}

import { call, CallEffect, put, PutEffect, takeEvery, takeLatest } from 'redux-saga/effects';
import { deleteDocumentCall, getDocumentCall, getDocumentInfoCall, getDocumentList, SUCCESS } from './apiCalls';
import {
    deleteDocumentError,
    deleteDocumentSuccess,
    getDocumentError,
    getDocumentInfoError,
    getDocumentInfoSuccess,
    getDocumentSuccess,
    userSlice,
} from '../userSlice';
import {
    DeleteDocumentPayloadType,
    DocumentInfoResponse,
    DocumentListResponse,
    GetDocumentInfoPayloadType,
    GetDocumentListPayloadType,
} from '../../utils/types';
import { AnyAction, PayloadAction } from '@reduxjs/toolkit';

export const { getDocumentListSuccess, getDocumentListError } = userSlice.actions;

export function* watchGetDocumentList() {
    yield takeLatest(userSlice.actions.getDocumentList, performGetDocumentList);
}

function* performGetDocumentList(action: PayloadAction<GetDocumentListPayloadType>): Generator<
    // step types
    CallEffect<any> | PutEffect<AnyAction>,
    // return type
    void,
    // intermediate argument
    any
> {
    try {
        const documentList: DocumentListResponse = yield call(getDocumentList, action.payload);
        yield put(getDocumentListSuccess(documentList));
    } catch (error: any) {
        console.log(error);
        yield put(getDocumentListError(error));
    }
}

export function* watchGetDocumentInfo() {
    yield takeEvery(userSlice.actions.getDocumentInfo, performGetDocumentInfo);
}

function* performGetDocumentInfo(action: PayloadAction<GetDocumentInfoPayloadType>): Generator<
    // step types
    CallEffect<any> | PutEffect<AnyAction>,
    // return type
    void,
    // intermediate argument
    any
> {
    const documentId = action.payload.documentId;
    try {
        const documentInfo: DocumentInfoResponse = yield call(getDocumentInfoCall, action.payload);
        if (!documentInfo.message && documentInfo.url) {
            const document = yield call(getDocumentCall, documentInfo);
            yield put(getDocumentInfoSuccess({ documentId, ...documentInfo }));
            yield put(getDocumentSuccess({ documentId, document }));
        } else {
            throw new Error('Failed getting signed url');
        }
    } catch (error: any) {
        console.log(error);
        yield put(getDocumentInfoError({ documentId, message: error }));
        yield put(getDocumentError({ documentId, message: error }));
    }
}

export function* watchDeleteDocument() {
    yield takeEvery(userSlice.actions.deleteDocument, performDeleteDocument);
}

function* performDeleteDocument(
    action: PayloadAction<DeleteDocumentPayloadType>
): Generator<CallEffect<any> | PutEffect<AnyAction>, void, any> {
    try {
        const response: string = yield call(deleteDocumentCall, action.payload);
        if (response === SUCCESS) {
            yield put(deleteDocumentSuccess(action.payload.itemId));
        } else {
            yield put(deleteDocumentError({ message: response, documentId: action.payload.itemId }));
        }
    } catch (error: any) {
        console.log(error);
        yield put(deleteDocumentError(error));
    }
}

const ENVIRONMENT_STAGE_MAPPING = {
    development: '/dev',
    production: '',
    staging: '/staging',
};
export const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
// @ts-ignore
export const environment: 'development' | 'production' | 'staging' | undefined = process.env.REACT_APP_ENVIRONMENT;

const joinUrls = function joinUrls(base: string, ...paths: string[]) {
    if (!paths || !paths.length) {
        return base;
    }

    return paths.reduce((path, part) => {
        if (!part || !part.length) {
            return path;
        }
        const separator = part.startsWith('/') ? '' : '/';
        return `${path}${separator}${part}`;
    }, base);
};

function baseUrl(variableName: string, defaultValue?: string): string {
    let value = process.env[variableName] || defaultValue;
    if (!value) {
        throw new Error(`Unable to get base URL for ${variableName} (default: ${defaultValue})`);
    }
    if (value.endsWith('/')) {
        value = value.slice(0, value.length - 1);
    }
    return value;
}
const metasearchRoot = baseUrl('REACT_APP_METASEARCH_ROOT');
const staticContentBaseUrl = baseUrl('REACT_APP_STATIC_ROOT', metasearchRoot);

const cssBaseUrl = baseUrl(
    'REACT_APP_CSS_ROOT',
    joinUrls(staticContentBaseUrl, 'styles', environment !== undefined ? ENVIRONMENT_STAGE_MAPPING[environment] : 'dev')
);
export const cssUrl = (...path: string[]) => joinUrls(cssBaseUrl, ...path);
function cacheBuster() {
    if (!buildNumber) {
        return '?' + Math.floor(Date.now());
    } else {
        return '?' + buildNumber;
    }
}
export function initStyleCss() {
    const link = document.createElement('link');

    link.rel = 'preload';
    link.as = 'style';
    link.onload = () => {
        link.rel = 'stylesheet';
    };
    link.href = `${cssUrl('/metasearch.css')}${cacheBuster()}`;

    if (navigator.appCodeName === 'Mozilla') {
        link.rel = 'stylesheet';
    }

    document.getElementsByTagName('head')[0].appendChild(link);
}

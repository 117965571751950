import React, { ReactChild, ReactChildren, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

type ComboLinkType = {
    active: boolean;
    className?: string;
    to: string;
    onClick: (e: SyntheticEvent) => void;
    children: ReactChild | ReactChildren;
    props?: any;
};

const ComboLink = ({ active, className, to, children, ...props }: ComboLinkType) => {
    const isExternalLink = to.substring(0, 8) === 'https://';

    if (!className) {
        className = 'navbar-brand navbar-brand-image';
    }

    return isExternalLink ? (
        <a className={className} href={to}>
            {children}
        </a>
    ) : (
        <NavLink
            className={className + (active && ' active')}
            // isActive={() => active}
            to={to}
            {...props}
        >
            {children}
        </NavLink>
    );
};

ComboLink.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    to: PropTypes.string,
};

export default ComboLink;

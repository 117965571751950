import React, { ReactChild, ReactChildren } from 'react';
import styles from './BaseLayout.module.css';

type BaseLayoutProps = { children: ReactChild | ReactChildren; footer: ReactChild; header: ReactChild };

function BaseLayout({ children, footer, header }: BaseLayoutProps) {
    return (
        <div className={styles.layout}>
            <div className={styles.header}>{header}</div>
            <div className={styles.mainContent}>
                <div className={styles.sidePadding} />
                <div className={styles.contentContainer}>{children}</div>
                <div className={styles.sidePadding} />
            </div>
            <div className={styles.footer}>{footer}</div>
        </div>
    );
}

export default BaseLayout;

import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import DocumentOverviewPage from './pages/DocumentOverview/DocumentOverviewPage';
import { RequireAuth } from './components/RequireAuth';
import DocumentView from './pages/DocumentView/DocumentView';
import BaseLayout from './components/BaseLayout';
import LoginPage from './pages/LoginPage/LoginPage';
import { Auth, Hub } from 'aws-amplify';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { logOutUser, selectUser, setUser } from './app/userSlice';
import PageHeader from './components/PageHeader';
import { TextField } from '@mui/material';
import './i18n';

const AuthListener = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                case 'cognitoHostedUI':
                    getUser().then(userData => userData && dispatch(setUser(userData)));
                    break;
                case 'signOut':
                    dispatch(logOutUser());
                    break;
                case 'signIn_failure':
                case 'cognitoHostedUI_failure':
                    console.log('Sign in failure', data);
                    break;
            }
        });

        getUser().then(userData => userData && dispatch(setUser(userData)));
    }, [dispatch]);

    function getUser() {
        return Auth.currentAuthenticatedUser()
            .then(userData => userData)
            .catch(() => console.log('Not signed in'));
    }

    return <></>;
};

export type PageHeaderContentType = {
    menu: { name: NameType; label: string; link: string }[];
};

export type NameType = 'LogOut';

function App() {
    const user = useAppSelector(selectUser);
    let PageHeaderContent = [];
    const location = useLocation();

    useEffect(() => {
        if (location?.pathname !== '/' && location?.pathname !== '/login' && !localStorage.getItem('loginRedirect')) {
            localStorage.setItem('loginRedirect', location?.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logOut: 'LogOut' = 'LogOut';
    user &&
        // @ts-ignore
        PageHeaderContent.push({ name: logOut, label: 'Log out', link: '/logout', onClick: () => Auth.signOut() });
    return (
        <>
            <AuthListener />
            <BaseLayout footer={<></>} header={<PageHeader menu={PageHeaderContent} />}>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <RequireAuth>
                                <DefPage />
                            </RequireAuth>
                        }
                    />
                    <Route path='/login' element={<LoginPage />} />
                    <Route
                        path='/:userid'
                        element={
                            <RequireAuth>
                                <DocumentOverviewPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path='/:userid/:documentType/:documentId'
                        element={
                            <RequireAuth>
                                <DocumentView />
                            </RequireAuth>
                        }
                    />
                </Routes>
            </BaseLayout>
        </>
    );
}

export default App;

const DefPage = () => {
    const navigate = useNavigate();

    return (
        <div className='App'>
            <header className='App-header'>
                <h3>Find user by id</h3>
                <div style={{ minWidth: '50%' }}>
                    <i className={'icon-user-full'} />
                    <TextField
                        sx={{
                            '& .MuiFilledInput-underline:after': { borderBottomColor: 'rgb(50, 55, 75)' },
                        }}
                        inputProps={{ style: { fontSize: 20 } }} // font size of input text
                        autoFocus
                        fullWidth
                        variant='filled'
                        placeholder={'user id ex: 0050Q000005mRgWQAU'}
                        onKeyPress={(event: any) => {
                            if (event.charCode === 13) {
                                navigate(event.target.value);
                            }
                        }}
                    />
                </div>
            </header>
        </div>
    );
};

import ComboLink from './ComboLink';
import { useTranslation } from 'react-i18next';
import React, { SyntheticEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NameType } from '../App';

function removePageParam(str: string) {
    return str
        .substring(1)
        .split('&')
        .filter(param => param.split('=')[0] !== 'page' && param)
        .join('&');
}

function navigateWithQueryParams(url: string, e: SyntheticEvent, history: (s: string) => void) {
    const pageQueryParamRemoved = removePageParam(window.location.search);
    if (pageQueryParamRemoved) {
        history(url + '?' + pageQueryParamRemoved);
    } else {
        history(url);
    }
}
export type MenuItemType = {
    active: boolean;
    onClick: (e: SyntheticEvent, name: string) => void;
    name: NameType;
    label: string;
    link: string;
};

const MenuItem = ({ active, onClick, name, label, link }: MenuItemType) => {
    const { t } = useTranslation();
    const history = useNavigate();

    if (!label) {
        // @ts-ignore
        label = t(`components.navBar.${name}`);
    }

    if (!link) {
        link = t(`links.community.${name}`);
    }

    return (
        <li>
            <ComboLink
                active={active}
                onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    navigateWithQueryParams(link, e, history);
                    if (onClick) {
                        onClick(e, name);
                    }
                }}
                to={link}
            >
                {label}
            </ComboLink>
        </li>
    );
};

MenuItem.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default MenuItem;

import {
    DocumentListResponse,
    DocumentInfoResponse,
    GetDocumentPayloadType,
    DocumentResponse,
    GetDocumentInfoPayloadType,
    GetDocumentListPayloadType,
    DeleteDocumentPayloadType,
} from '../../utils/types';

const apiRoot = process.env.REACT_APP_API_ROOT || 'https://api.sdiv.dev.ownr.eu';

export async function getDocumentList({ userid, jwtToken }: GetDocumentListPayloadType): Promise<DocumentListResponse> {
    try {
        const res = await fetch(`${apiRoot}/overview?userid=${userid}`, {
            headers: { Authorization: jwtToken },
        });
        if (res.status === 200) {
            const json = await res.json();
            return json;
        } else {
            const json = await res.json();
            // return (json.message);
            // @ts-ignore
            throw new Error(json.message);
        }
    } catch (error: any) {
        throw new Error(error);
    }
}

export async function getDocumentInfoCall({
    userid,
    jwtToken,
    documentType,
    documentId,
}: GetDocumentInfoPayloadType): Promise<DocumentInfoResponse> {
    try {
        const res = await fetch(
            `${apiRoot}/document?userid=${userid}&documenttype=${documentType}&itemid=${documentId}`,
            {
                headers: { Authorization: jwtToken },
            }
        );
        if (res.status === 200) {
            const json = (await res.json()) as DocumentInfoResponse;
            return json;
        } else {
            const json = await res.json();
            // return (json.message);
            // @ts-ignore
            throw new Error(json.message);
        }
    } catch (error: any) {
        throw new Error(error);
    }
}

export async function getDocumentCall({ sseKey, sseKeyMd5, url }: GetDocumentPayloadType): Promise<string | undefined> {
    try {
        const res = await fetch(url, {
            method: 'GET',
            headers: {
                'x-amz-server-side-encryption-customer-key': sseKey + '==',
                'x-amz-server-side-encryption-customer-key-MD5': sseKeyMd5 + '==',
                'x-amz-server-side-encryption-customer-algorithm': 'AES256',
            },
        });
        if (res.status === 200) {
            // res.blob().then(blob => download(blob, 'derp')); //TODO works for dl
            return res.blob().then(blob => URL.createObjectURL(blob)); //TODO work for dl
        } else {
            const { message } = (await res.json()) as DocumentResponse;
            // @ts-ignore
            throw new Error(message);
        }
    } catch (error: any) {}
}

export const SUCCESS: 'SUCCESS' = 'SUCCESS';

export async function deleteDocumentCall(document: DeleteDocumentPayloadType): Promise<string | undefined> {
    const { userId, documentType, itemId, jwtToken } = document;

    try {
        const url = `${apiRoot}/document?userid=${userId}&documenttype=${documentType}&itemid=${itemId}`;
        const res = await fetch(url, {
            method: 'DELETE',
            headers: { Authorization: jwtToken },
        });
        if (res.status === 200) {
            return SUCCESS;
        } else {
            const { message } = (await res.json()) as DocumentResponse;
            // @ts-ignore
            throw new Error(message);
        }
    } catch (error: any) {}
}

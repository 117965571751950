import React from 'react';

export const DocTypeIcon = ({ documentType }: { documentType: string }) => {
    const ID_DOCUMENT_COPY: 'ID_DOCUMENT_COPY' = 'ID_DOCUMENT_COPY';
    const INCOME_STATEMENT: 'INCOME_STATEMENT' = 'INCOME_STATEMENT';
    const PROOF_OF_EMPLOYMENT: 'PROOF_OF_EMPLOYMENT' = 'PROOF_OF_EMPLOYMENT';
    const SCHUFA: 'SCHUFA' = 'SCHUFA';
    const APPLICATION_PORTFOLIO: 'APPLICATION_PORTFOLIO' = 'APPLICATION_PORTFOLIO';

    let iconclassName = '';
    switch (documentType) {
        case INCOME_STATEMENT:
            iconclassName = 'sdiv-icon-money';
            break;
        case PROOF_OF_EMPLOYMENT:
            iconclassName = 'sdiv-icon-briefcase';
            break;
        case ID_DOCUMENT_COPY:
            iconclassName = 'sdiv-icon-list-alt';
            break;
        case APPLICATION_PORTFOLIO:
            iconclassName = 'sdiv-icon-folder-open-empty';
            break;
        case SCHUFA:
            iconclassName = 'sdiv-icon-credit-card';
            break;
        default:
            iconclassName = 'icon-dashboard-notebook';
    }
    return <i className={iconclassName} />;
};

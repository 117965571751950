import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import de from './translations/de.json';

const resources = {
    en,
    de,
};

i18n.use(initReactI18next).init({
    resources,
    defaultNS: 'sdiv',
    fallbackLng: 'de',
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
});
